@import "material-dashboard-pro/scss/material-dashboard-pro-react";

:root {
  --hl-color-primary: #{$brand-primary};
  --hl-color-warning: #{$brand-warning};
  --hl-color-danger: #{$brand-danger};
  --hl-color-rose: #{$brand-rose};
  --hl-color-success: #{$brand-success};
  --hl-color-info: #{$brand-info};
  --hl-color-gray: #999999;

	--hl-accessibility-color-warning: #{$brand-warning};
	--hl-accessibility-color-success: #2734a8;

  --hl-level-color-novice: #00bcd4;
  --hl-level-color-easy: #4caf50;
	--hl-level-color-medium: #ff9800;
	--hl-level-color-hard: #e91e63;
	--hl-level-color-leet: #444444;

	--hl-secondary-text-color: #ccc;
}

body.hl--dark-mode {
	--hl-secondary-text-color: #999;
}

a {
	color: var(--hl-color-info);
}
